//
// Callouts
// --------------------------------------------------

.callout {
    margin-bottom: @line-height-computed;
    padding: @callout-padding;
    border-left: 3px solid @callout-border;
    code, .highlight {
    }
    h4 {
        margin-top: 0;
        margin-bottom: 5px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}

.callout-danger {
    background-color: @callout-danger-bg;
    border-color: @callout-danger-border;
    h4 {
        color: @callout-danger-text;
    }
}

.callout-warning {
    background-color: @callout-warning-bg;
    border-color: @callout-warning-border;
    h4 {
        color: @callout-warning-text;
    }
}

.callout-info {
    background-color: @callout-info-bg;
    border-color: @callout-info-border;
    h4 {
        color: @callout-info-text;
    }
}

.callout-success {
    background-color: @callout-success-bg;
    border-color: @callout-success-border;
    h4 {
        color: @callout-success-text;
    }
}
