.navbar-nav li.separator {
    width: 1px;
    background: rgba(255, 255, 255, 0.3);
    height: 30px;
    margin: 20px 10px 0 10px;
}

.navbar-autohide {
    transition: transform .5s;
}

.navbar-autohide.is-hidden {
    transform: translateY(-(@navbar-height + 2px));
}

.navbar-collapse:not(.in):not(.collapsing) .navbar-nav li {

    > a {
        transition: color 0.2s ease 0.05s;
    }

    > a:after {
        position: absolute;
        height: 4px;
        bottom: -1px;
        content: '';
        border-radius: 4px;
        z-index: 5;
        width: 0;
        left: 50%;
        transition: all 0.2s ease 0.05s;
    }

    &.active > a:after,
    > a:hover:after {
        background: @navbar-link-stripe-color-active;
    }

    &.active > a:after {
        width: 100% !important;
        left: 0 !important;
    }

    &:hover > a:after {
        width: 100%;
        left: 0;
    }

    &.active > a:hover:after {
        background: @navbar-link-stripe-color-active;
    }

    > a:hover:after {
        background: @navbar-link-stripe-color-hover;
    }
}

.navbar {
    min-height: 0;
    
    .navbar-toggle {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .navbar-brand {
        height: 59px;
        padding: 10px 10px 10px 0;
        float: none;

    }

    .mobile-back-button {
        display: block !important;
        float:left;
        padding-top: 0.9em;

        a {
            display: block;
            color: white;
            padding: 5px 0 0 8px;
        }
    }
}

html, body{ 
  min-width:100%;
}
