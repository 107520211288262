//
// Layouts
//
// Styles specific to different page layouts.
//

// Used by the fixed navbar
body {
    padding-top: 70px;
}
