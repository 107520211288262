//
// Typography
// --------------------------------------------------

.t-ww { word-wrap: break-word; word-break: break-word; }

//
// Borders
// --------------------------------------------------

.border-none { border: 0; }

//
// Positioning
// --------------------------------------------------

.pos-r { position: relative !important; }
.pos-a { position: absolute !important; }
.pos-f { position: fixed !important; }

//
// Width
// --------------------------------------------------

.w-sm   { width: 25% !important; }
.w-md   { width: 50% !important; }
.w-lg   { width: 75% !important; }
.w-full { width: 100% !important; }
.w-50   { width: 50px !important; }
.w-100  { width: 100px !important; }
.w-120  { width: 120px !important; }
.w-130  { width: 130px !important; }
.w-140  { width: 140px !important; }
.w-150  { width: 150px !important; }
.w-200  { width: 200px !important; }
.w-300  { width: 300px !important; }
.w-350  { width: 350px !important; }

//
// Margins
// --------------------------------------------------

.m-a-0 { margin:        0 !important; }
.m-t-0 { margin-top:    0 !important; }
.m-r-0 { margin-right:  0 !important; }
.m-b-0 { margin-bottom: 0 !important; }
.m-l-0 { margin-left:   0 !important; }

.m-a { margin:        @spacer !important; }
.m-t { margin-top:    @spacer-y !important; }
.m-r { margin-right:  @spacer-x !important; }
.m-b { margin-bottom: @spacer-y !important; }
.m-l { margin-left:   @spacer-x !important; }
.m-x { margin-right:  @spacer-x !important; margin-left: @spacer-x !important; }
.m-y { margin-top:    @spacer-y !important; margin-bottom: @spacer-y !important; }
.m-x-auto { margin-right: auto !important; margin-left: auto !important; }

.m-a-xs { margin:        (@spacer-y / 4) !important; }
.m-t-xs { margin-top:    (@spacer-y / 4) !important; }
.m-r-xs { margin-right:  (@spacer-y / 4) !important; }
.m-b-xs { margin-bottom: (@spacer-y / 4) !important; }
.m-l-xs { margin-left:   (@spacer-y / 4) !important; }
.m-x-xs { margin-right:  (@spacer-x / 4) !important; margin-left:   (@spacer-x / 4) !important; }
.m-y-xs { margin-top:    (@spacer-y / 4) !important; margin-bottom: (@spacer-y / 4) !important; }

.m-a-sm { margin:        (@spacer-y / 2) !important; }
.m-t-sm { margin-top:    (@spacer-y / 2) !important; }
.m-r-sm { margin-right:  (@spacer-y / 2) !important; }
.m-b-sm { margin-bottom: (@spacer-y / 2) !important; }
.m-l-sm { margin-left:   (@spacer-y / 2) !important; }
.m-x-sm { margin-right:  (@spacer-x / 2) !important; margin-left:   (@spacer-x / 2) !important; }
.m-y-sm { margin-top:    (@spacer-y / 2) !important; margin-bottom: (@spacer-y / 2) !important; }

.m-a-md { margin:        (@spacer-y * 1.5) !important; }
.m-t-md { margin-top:    (@spacer-y * 1.5) !important; }
.m-r-md { margin-right:  (@spacer-y * 1.5) !important; }
.m-b-md { margin-bottom: (@spacer-y * 1.5) !important; }
.m-l-md { margin-left:   (@spacer-y * 1.5) !important; }
.m-x-md { margin-right:  (@spacer-x * 1.5) !important; margin-left:   (@spacer-x * 1.5) !important; }
.m-y-md { margin-top:    (@spacer-y * 1.5) !important; margin-bottom: (@spacer-y * 1.5) !important; }

.m-a-lg { margin:        (@spacer-y * 3) !important; }
.m-t-lg { margin-top:    (@spacer-y * 3) !important; }
.m-r-lg { margin-right:  (@spacer-y * 3) !important; }
.m-b-lg { margin-bottom: (@spacer-y * 3) !important; }
.m-l-lg { margin-left:   (@spacer-y * 3) !important; }
.m-x-lg { margin-right:  (@spacer-x * 3) !important; margin-left:   (@spacer-x * 3) !important; }
.m-y-lg { margin-top:    (@spacer-y * 3) !important; margin-bottom: (@spacer-y * 3) !important; }

//
// Padding
// --------------------------------------------------

.p-a-0 { padding:        0 !important; }
.p-t-0 { padding-top:    0 !important; }
.p-r-0 { padding-right:  0 !important; }
.p-b-0 { padding-bottom: 0 !important; }
.p-l-0 { padding-left:   0 !important; }

.p-a { padding:        @spacer !important; }
.p-t { padding-top:    @spacer-y !important; }
.p-r { padding-right:  @spacer-x !important; }
.p-b { padding-bottom: @spacer-y !important; }
.p-l { padding-left:   @spacer-x !important; }
.p-x { padding-right:  @spacer-x !important; padding-left:   @spacer-x !important; }
.p-y { padding-top:    @spacer-y !important; padding-bottom: @spacer-y !important; }

.p-a-xs { padding:        (@spacer-y / 4) !important; }
.p-t-xs { padding-top:    (@spacer-y / 4) !important; }
.p-r-xs { padding-right:  (@spacer-y / 4) !important; }
.p-b-xs { padding-bottom: (@spacer-y / 4) !important; }
.p-l-xs { padding-left:   (@spacer-y / 4) !important; }
.p-x-xs { padding-right:  (@spacer-x / 4) !important; padding-left:   (@spacer-x / 4) !important; }
.p-y-xs { padding-top:    (@spacer-y / 4) !important; padding-bottom: (@spacer-y / 4) !important; }

.p-a-sm { padding:        (@spacer-y / 2) !important; }
.p-t-sm { padding-top:    (@spacer-y / 2) !important; }
.p-r-sm { padding-right:  (@spacer-y / 2) !important; }
.p-b-sm { padding-bottom: (@spacer-y / 2) !important; }
.p-l-sm { padding-left:   (@spacer-y / 2) !important; }
.p-x-sm { padding-right:  (@spacer-x / 2) !important; padding-left:   (@spacer-x / 2) !important; }
.p-y-sm { padding-top:    (@spacer-y / 2) !important; padding-bottom: (@spacer-y / 2) !important; }

.p-a-md { padding:        (@spacer-y * 1.5) !important; }
.p-t-md { padding-top:    (@spacer-y * 1.5) !important; }
.p-r-md { padding-right:  (@spacer-y * 1.5) !important; }
.p-b-md { padding-bottom: (@spacer-y * 1.5) !important; }
.p-l-md { padding-left:   (@spacer-y * 1.5) !important; }
.p-x-md { padding-right:  (@spacer-x * 1.5) !important; padding-left:   (@spacer-x * 1.5) !important; }
.p-y-md { padding-top:    (@spacer-y * 1.5) !important; padding-bottom: (@spacer-y * 1.5) !important; }

.p-a-lg { padding:        (@spacer-y * 3) !important; }
.p-t-lg { padding-top:    (@spacer-y * 3) !important; }
.p-r-lg { padding-right:  (@spacer-y * 3) !important; }
.p-b-lg { padding-bottom: (@spacer-y * 3) !important; }
.p-l-lg { padding-left:   (@spacer-y * 3) !important; }
.p-x-lg { padding-right:  (@spacer-x * 3) !important; padding-left:   (@spacer-x * 3) !important; }
.p-y-lg { padding-top:    (@spacer-y * 3) !important; padding-bottom: (@spacer-y * 3) !important; }
